@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  flex: 1;
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');


.merri{
  font-family: "Merriweather", serif;
}

@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Cinzel:wght@400..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');

.arvo{
  font-family: "Alegreya", serif;
}


@font-face {
  font-family: "celinda";
  src: url('./assets/font/seasons.woff2');
  font-style: normal;
}

.cel {
  font-family: "celinda";
}

.cel2{
  font-family: "Playfair Display", serif;;
}

.animate__zoomIn:hover {
  animation: myAnim 0.5s ease-in-out 0s 1 normal forwards;
}
@keyframes myAnim {
  0% {
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    transform: scale(1);
  }

  100% {
    /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); */
    transform: scale(1.03);
  }
}

.animate__zoomIn2:hover {
  animation: myAnim2 0.5s ease-in-out 0s 1 normal forwards;
}
@keyframes myAnim2 {
  0% {
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    transform: scale(1);
  }

  100% {
    /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); */
    transform: scale(1.3);
  }
}

ul.thumbs {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  font-family: "merriweather";
  color: #222222 !important;
}

.slick-prev {
  z-index: 1000;
  margin-left: 40px;
  color: #f5f5f5;
  ::before {
    background-color: #f5f5f5;
  }
}

.slick-next {
  z-index: 1000;
  margin-right: 40px;
  color: #f5f5f5 !important;
}
.slick-next:before,
.slick-prev:before {
  opacity: unset !important;
  font-size: 22px;
}

.slick-dots {
  position: absolute;
  bottom: 40px !important;
}

.slick-dots li {
  margin: 0px !important;
}

.slick-dots li button:before {
  color: gray !important;
  font-size: 10px !important;
  padding: 0px !important;
  opacity: unset !important;
}

@media screen and (max-width: 768px) {
  .slick-prev {
    margin-left: 30px;
  }

  .slick-next {
    margin-right: 30px;
  }
  .slick-dots {
    display: none !important;
  }
}

.slick-dots li.slick-active button:before {
  color: white !important;
  font-size: 10px !important;
  padding: 0px !important;
  opacity: unset !important;
}
